import React from "react";
import MaskInput from "lib/maskInput";
import {
  DataFieldAffixEl,
  DataFieldEl,
  DataFieldErrorEl,
  DataFieldInputEl,
  DataFieldLabelEl,
  DataFieldProps,
  DataFieldRow
} from "atom/datafield/dataFieldComponents";

function DataField(props: DataFieldProps) {
  const {
    label,
    affix,
    mask,
    focusOnSelect = true,
    onChangeCapture,
    error,
    errorParser = ({ error }) => {
      if (!error) return "";
      if (typeof error === "string") return error;
      return error.message;
    },
    ...textFieldProps
  } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);

  const focusInput = () => {
    inputRef.current?.focus();
    if (
      focusOnSelect &&
      !textFieldProps.isReadOnly &&
      !textFieldProps.isDisabled
    ) {
      inputRef.current?.setSelectionRange(0, 9999);
    }
  };

  React.useEffect(() => {
    if (!inputRef.current || !mask) return;
    const instance = new MaskInput(inputRef.current, mask);

    setTimeout(() => {
      if (inputRef.current && textFieldProps.defaultValue) {
        instance.maskInstance?.updateControl();
      }
    }, 0);

    return () => {
      instance.destroy();
    };
  }, [inputRef.current, mask, textFieldProps.defaultValue]);

  React.useImperativeHandle(
    props.ref,
    () => {
      const state = {
        setValue: (value: string) => {
          setTimeout(() => {
            if (inputRef.current) {
              inputRef.current.value = value;
            }
          });
        },
        input: inputRef.current
      };
      return state;
    },
    [inputRef.current]
  );

  const errorString = errorParser(props);
  return (
    <DataFieldEl
      {...textFieldProps}
      ref={null}
      isInvalid={Boolean(error)}
      className="data-field"
    >
      <DataFieldRow onClick={focusInput} className="data-field-row">
        <DataFieldLabelEl className="body1">{label}</DataFieldLabelEl>
        <DataFieldInputEl
          className="body1"
          onChangeCapture={onChangeCapture}
          data-has-affix={Boolean(affix)}
          ref={inputRef}
        />
        {affix && (
          <DataFieldAffixEl
            className="body1 data-field-affix"
            slot="description"
          >
            {affix}
          </DataFieldAffixEl>
        )}
      </DataFieldRow>
      <DataFieldErrorEl className={"little1 data-field-error"}>
        {errorString}
      </DataFieldErrorEl>
    </DataFieldEl>
  );
}

export default DataField;
